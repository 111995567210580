import React from 'react';
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import aa from '../../assets/certs/aa_cert.pdf';
import aaPic from '../../assets/certs/aa_cert.png';
import GooglePM from '../../assets/certs/google_pm.pdf';
import GooglePMPic from '../../assets/certs/google_pm.png';
import MetaFE from '../../assets/certs/meta_fe.pdf';
import MetaFEPic from '../../assets/certs/meta_fe.png';
import './MyCredentials.css';

export default function MyCredentials() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    return (
        <div className="projects-container">
            <div className="heading-container my-apps">
                <h3 className="heading-number">04.</h3>
                <h3 className="heading-text">{content.creds}</h3>
            </div>

            <div className='about-me-body-container'>
                <div className='about-me-body'>

                    <div className='other-projects'>
                        <div className='epic'>
                            <div className='project-description'>
                                <a className='project-name' href='https://www.linkedin.com/in/isaiahxs/overlay/1635537304895/single-media-viewer/?profileId=ACoAACct6lYBG9Og4R6uOlnzcVuTv7r53vIxpAg' target='_blank' rel='noopener noreferrer'>App Academy</a>
                                <div className='project-description-text'>
                                    {content.AA1}
                                    <br />
                                    <br />
                                    {content.AA2}
                                    <br />
                                    <br />
                                    {content.AA3}
                                    <br />
                                </div>

                            </div>
                            <div className='about-img-container'>
                                <a href={aa} target='_blank' rel='noopener noreferrer'>
                                    <img className='project-image' src={aaPic} alt='App Academy Certificate of Completion' />
                                </a>
                                <div className='project-links'>
                                    <a className='external-link' href='https://www.linkedin.com/in/isaiahxs/overlay/1635537304895/single-media-viewer/?profileId=ACoAACct6lYBG9Og4R6uOlnzcVuTv7r53vIxpAg' target='_blank' rel='noopener noreferrer'>
                                        <button className='live-site'>{content.verify}</button>
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div className='serene-haven'>
                            <div className='project-description'>
                                <a className='project-name' href='https://www.coursera.org/account/accomplishments/professional-cert/F4X944E6A4AE' target='_blank' rel='noopener noreferrer'>{content.MetaTitle}</a>
                                <div className='project-description-text'>
                                    {content.Meta1}
                                </div>
                            </div>
                            <div className='about-img-container'>
                                <a href={MetaFE} target='_blank' rel='noopener noreferrer'>
                                    <img className='project-image' src={MetaFEPic} alt='serene-haven project' />
                                </a>
                                <div className='project-links'>
                                    <a className='external-link' href='https://www.coursera.org/account/accomplishments/professional-cert/F4X944E6A4AE' target='_blank' rel='noopener noreferrer'>
                                        <button className='live-site'>{content.verify}</button>
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div className='well-done'>
                            <div className='project-description'>
                                <a className='project-name' href='https://www.coursera.org/account/accomplishments/professional-cert/845ZJZ8FFVT7' target='_blank' rel='noopener noreferrer'>{content.GoogleTitle}</a>
                                <div className='project-description-text'>
                                    {content.Google1}
                                </div>
                            </div>
                            <div className='about-img-container'>
                                <a href={GooglePM} target='_blank' rel='noopener noreferrer'>
                                    <img className='project-image' src={GooglePMPic} alt='Google Project Management Specialization' />
                                </a>
                                <div className='project-links'>
                                    <a className='external-link' href='https://www.coursera.org/account/accomplishments/professional-cert/845ZJZ8FFVT7' target='_blank' rel='noopener noreferrer'>
                                        <button className='live-site'>{content.verify}</button>
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div className='in-progress-section'>
                            <div className='in-progress'>
                                {content.progress}
                            </div>
                            <div className='in-progress-name project-name'>
                                {content.GoogleDA}
                            </div>
                            <div className='in-progress-name project-name'>
                                {content.MetaBE}
                            </div>
                            <div className='in-progress-name project-name'>
                                {content.GoogleDM}
                            </div>
                            <div className='in-progress-name project-name'>
                                {content.AWS}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}