export const englishContent = {
    creds: "My Credentials",

    AA1: "Successfully completed the immersive App Academy Software Engineering Curriculum, an intensive year-long program spanning 888 hours with less than a 3% acceptance rate.",
    AA2: "Throughout this rigorous program, I honed my coding skills through daily pair programming and weekly assessments, demonstrating mastery of each topic. The curriculum emphasized agile software development, test-driven development, and pair programming, fostering collaboration and effective project management skills.",
    AA3: "This experience allowed me to build real-world web applications, from concept to deployment, and refine my technical interview skills. The projects created during this program are showcased in my portfolio, highlighting my ability to design and implement complex software solutions.",

    MetaTitle: "Facebook/Meta Front-End Developer",
    Meta1: "Successfully completed the Facebook/Meta Front-End Developer Specialization, a comprehensive 9-course program spanning 7 months. This intensive curriculum delved into the latest web development technologies, equipping me with advanced skills in building user-friendly web interfaces, optimizing web performance, and ensuring cross-browser compatibility. With this specialized knowledge, I'm poised to create visually stunning and responsive web experiences that captivate users and drive engagement.",

    GoogleTitle: "Google Project Management",
    Google1: "Comprehensive 6 course program over 6 months offered by Google. This specialized training has equipped me with a deep understanding of project management methodologies, Agile practices, strategic communication, and stakeholder management. With this valuable credential, I bring top-tier project management expertise to every endeavor, ensuring efficient execution and exceptional results.",

    GoogleDA: "Google Data Analytics",
    MetaBE: "Facebook/Meta Back-End Developer",
    GoogleDM: "Google Digital Marketing & E-Commerce",
    AWS: "AWS Cloud Practicioner",

    progress: "In Progress:",
    verify: "Verify Credential"
}

export const spanishContent = {
    creds: "Mis Credenciales",

    AA1: "Completé con éxito el plan de estudios inmersivo de ingeniería de software de App Academy, un programa intensivo de un año que abarca 888 horas con menos del 3% de aceptación.",
    AA2: "A lo largo de este riguroso programa, perfeccioné mis habilidades de codificación a través de la programación en parejas diaria y las evaluaciones semanales, demostrando el dominio de cada tema. El plan de estudios enfatizó el desarrollo ágil de software, el desarrollo impulsado por pruebas y la programación en parejas, fomentando la colaboración y las habilidades efectivas de gestión de proyectos.",
    AA3: "Esta experiencia me permitió construir aplicaciones web del mundo real, desde el concepto hasta la implementación, y perfeccionar mis habilidades de entrevista técnica. Los proyectos creados durante este programa se muestran en mi cartera, destacando mi capacidad para diseñar e implementar soluciones de software complejas.",

    MetaTitle: "Desarrollador Front-End de Facebook/Meta",
    Meta1: "Completé con éxito la Especialización de Desarrollador Front-End de Facebook/Meta, un programa integral de 9 cursos que abarca 7 meses. Este plan de estudios intensivo profundizó en las últimas tecnologías de desarrollo web, equipándome con habilidades avanzadas para crear interfaces web fáciles de usar, optimizar el rendimiento web y garantizar la compatibilidad entre navegadores. Con este conocimiento especializado, estoy preparado para crear experiencias web visualmente impresionantes y receptivas que cautiven a los usuarios y fomenten la participación.",

    GoogleTitle: "Gestión de Proyectos de Google",
    Google1: "Programa integral de 6 cursos durante 6 meses ofrecido por Google. Esta capacitación especializada me ha proporcionado una comprensión profunda de las metodologías de gestión de proyectos, las prácticas ágiles, la comunicación estratégica y la gestión de partes interesadas. Con esta valiosa credencial, aporto experiencia en gestión de proyectos de primer nivel a todos los esfuerzos, lo que garantiza una ejecución eficiente y resultados excepcionales.",

    GoogleDA: "Analítica de Datos de Google",
    MetaBE: "Desarrollador Back-End de Facebook/Meta",
    GoogleDM: "Marketing Digital y Comercio Electrónico de Google",
    AWS: "Practicante de la Nube AWS",

    progress: "En Progreso:",
    verify: "Verificar Credencial"
}