import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import './MyServices.css';

export default function MyServices() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    return (
        <section className="services-container">
            <div className="heading-container my-apps">
                <h3 className="heading-number">05.</h3>
                <h3 className="heading-text">{content.services}</h3>
            </div>

            <ul>
                <li>
                    <strong>{content.customerServiceHeader}</strong> {content.customerService}
                </li>
                {/* <li>
                    <strong>{content.comprehensiveHeader}</strong> {content.comprehensive}
                </li> */}
                <li>
                    <strong>{content.cuttingEdgeHeader}</strong> {content.cuttingEdge}
                </li>
                <li>
                    <strong>{content.graphicDesignHeader}</strong> {content.graphicDesign}
                </li>
                <li>
                    <strong>{content.enhancedHeader}</strong> {content.enhanced}
                </li>
                <li>
                    <strong>{content.dataDrivenHeader}</strong> {content.dataDriven}
                </li>
                <li>
                    <strong>{content.certificationsHeader}</strong> {content.certifications}
                </li>
            </ul>
        </section >
    )
}