export const englishContent = {
    apps: "My Applications",
    E1: "Epic-E-Shop is a user-centric e-commerce platform designed around the gaming community. Drawing from community-created APIs, the platform provides daily and featured items from Fortnite's store for users to browse through and purchase.",
    E2: "With features to create, view, update, and delete comments and votes on items, as well as a reminder system for favorited items, Epic-E-Shop bridges the gap between gaming and e-commerce, delivering a personalized shopping experience for gaming enthusiasts.",
    live: "Live Site",
    gh: "GitHub Repo",

    W1: "Well-Done is a collaboratively-made dynamic story-sharing social media application inspired by Medium. It provides users with a platform to create, view, update, and delete stories and comments.",
    W2: "Users can clap for stories and comments, follow and unfollow other users, and view a feed tailored to their interests. The platform facilitates a rich user experience by incorporating image upload capabilities with AWS and enabling users to tag stories and sort them by tags.",

    S1: "SereneHaven is a dynamic web application that empowers users to seamlessly manage rental listings, comments, bookings, and favorites. Offering a comprehensive range of functionalities - creation, viewing, updating, and deletion.",
    S2: "SereneHaven was developed using React.js, Redux, JavaScript, HTML, and CSS for the frontend, and Express, Node.js, and PostgreSQL for the backend. This project aims to provide an intuitive and feature-rich platform for an enhanced location-rental experience."
}

export const spanishContent = {
    apps: "Mis Aplicaciones",
    E1: "Epic-E-Shop es una plataforma de comercio electrónico centrada en el usuario diseñada en torno a la comunidad de jugadores. A partir de API creadas por la comunidad, la plataforma proporciona artículos diarios y destacados de la tienda de Fortnite para que los usuarios los naveguen y compren.",
    E2: "Con funciones para crear, ver, actualizar y eliminar comentarios y votos sobre artículos, así como un sistema de recordatorio para artículos favoritos, Epic-E-Shop acorta la brecha entre los juegos y el comercio electrónico, ofreciendo una experiencia de compra personalizada para los entusiastas de los juegos.",
    live: "Sitio en Vivo",
    gh: "Repositorio GitHub",

    W1: "Well-Done es una aplicación de redes sociales dinámica para compartir historias hecha en colaboración inspirada en Medium. Proporciona a los usuarios una plataforma para crear, ver, actualizar y eliminar historias y comentarios.",
    W2: "Los usuarios pueden aplaudir historias y comentarios, seguir y dejar de seguir a otros usuarios y ver un feed adaptado a sus intereses. La plataforma facilita una rica experiencia de usuario al incorporar capacidades de carga de imágenes con AWS y permitir a los usuarios etiquetar historias y ordenarlas por etiquetas.",

    S1: "SereneHaven es una aplicación web dinámica que permite a los usuarios administrar sin problemas listados de alquileres, comentarios, reservas y favoritos. Ofrece una amplia gama de funcionalidades: creación, visualización, actualización y eliminación.",
    S2: "SereneHaven fue desarrollado utilizando React.js, Redux, JavaScript, HTML y CSS para el frontend, y Express, Node.js y PostgreSQL para el backend. Este proyecto tiene como objetivo proporcionar una plataforma intuitiva y rica en funciones para una experiencia de alquiler de ubicación mejorada."
}