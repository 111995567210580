export const englishContent = {
    services: "My Services",

    customerServiceHeader: "Exceptional Customer Service",
    customerService: "Your satisfaction is my top priority. I am dedicated to providing ongoing support, making changes, and implementing edits to your website promptly. You can count on me to be responsive and address your needs as quickly as possible, ensuring your online presence remains up-to-date and effective.",

    comprehensiveHeader: "Comprehensive Services",
    comprehensive: "Beyond Web Development and SEO, I offer a wide range of services including Content Creation, Social Media Management, and Digital Marketing to ensure your online presence excels.",

    cuttingEdgeHeader: "Cutting-Edge Solutions",
    cuttingEdge: "I stay up-to-date with the latest trends in web development, SEO, and digital marketing to provide you with innovative solutions that keep you ahead of the competition.",

    graphicDesignHeader: "Graphic Design Expertise",
    graphicDesign: "I can craft stunning logos and visually appealing graphics to make your brand stand out.",

    enhancedHeader: "Enhanced Online Visibility",
    enhanced: "I specialize in optimizing your Google My Business profile and implementing SEO strategies to improve search rankings and boost your online presence.",

    dataDrivenHeader: "Data-Driven Insights",
    dataDriven: "With Google Analytics, I provide personalized reports and insights to help you make informed decisions for your business.",

    certificationsHeader: "Certifications and Specializations",
    certifications: "I hold certifications and specializations in Web Development, Project Management, and Data Analytics, from companies like Google, Facebook/Meta, with more in progress like AWS's Cloud Practicioner, ensuring my expertise is backed by industry-recognized qualifications."
}

export const spanishContent = {
    services: "Mis Servicios",

    customerServiceHeader: "Servicio al Cliente Excepcional:",
    customerService: "Su satisfacción es mi principal prioridad. Me dedico a brindar soporte continuo, realizar cambios e implementar ediciones en su sitio web de manera rápida. Puede contar conmigo para que sea receptivo y atienda sus necesidades lo más rápido posible, asegurando que su presencia en línea se mantenga actualizada y efectiva.",

    comprehensiveHeader: "Servicios Completos:",
    comprehensive: "Además del desarrollo web y la Optimización de Motores de Búsqueda, ofrezco una amplia gama de servicios que incluyen creación de contenido, administración de redes sociales y marketing digital para garantizar que su presencia en línea sea excelente.",

    cuttingEdgeHeader: "Soluciones Innovadoras:",
    cuttingEdge: "Me mantengo al día con las últimas tendencias en desarrollo web, Optimización de Motores de Búsqueda y marketing digital para brindarle soluciones innovadoras que lo mantengan por delante de la competencia.",

    graphicDesignHeader: "Experiencia en Diseño Gráfico:",
    graphicDesign: "Puedo crear logotipos impresionantes y gráficos visualmente atractivos para que su marca se destaque.",

    enhancedHeader: "Visibilidad en Línea Mejorada:",
    enhanced: "Me especializo en optimizar el perfil de su Google My Business e implementar estrategias de Optimización de Motores de Búsqueda para mejorar el ranking de búsqueda y aumentar su presencia en línea.",

    dataDrivenHeader: "Información Impulsada por Datos:",
    dataDriven: "Con Google Analytics, proporciono informes e información personalizados para ayudarlo a tomar decisiones informadas para su negocio.",

    certificationsHeader: "Certificaciones y Especializaciones:",
    certifications: "Tengo certificaciones y especializaciones en Desarrollo Web, Gestión de Proyectos y Analítica de Datos, de empresas como Google, Facebook/Meta, con más en progreso como el Practicante de la Nube de AWS, lo que garantiza que mi experiencia esté respaldada por calificaciones reconocidas por la industria."
}