export const englishContent = {
    about: "About Me",
    firstAbout: "As a dedicated freelancer, I've had the incredible opportunity to engage in a variety of challenging projects, all of which have helped to refine and enhance my skill set.",
    secondAbout: "I've also had the privilege of completing App Academy's prestigious coding bootcamp which has been a transformative experience that has further empowered my tech journey.",
    thirdAbout: "With specialized certifications from industry leaders such as Google and Facebook/Meta, I excel in crafting user-friendly websites that captivate and perform flawlessly. Furthermore, I'm committed to continuous growth, pursuing ongoing specializations from Amazon Web Services to stay at the forefront of cutting-edge technologies.",
    // thirdAbout: "When I'm not coding, I'm either playing soccer, or brushing up on my card counting skills!",
    proficiencies: "My Proficiencies Include:",
    fe: "Front-End Development",
    be: "Back-End Development",
    version: "Version Control & Collaboration",
    cloud: "Cloud Services"
}

export const spanishContent = {
    about: "Sobre Mi",
    firstAbout: "Como un freelancer dedicado, he tenido la increíble oportunidad de participar en una variedad de proyectos desafiantes, todos los cuales han ayudado a refinar y mejorar mi conjunto de habilidades.",
    secondAbout: "También he tenido el privilegio de completar el prestigioso bootcamp de codificación de App Academy, que ha sido una experiencia transformadora que ha fortalecido aún más mi viaje tecnológico.",
    thirdAbout: "Cuando no estoy codificando, ¡estoy jugando fútbol o perfeccionando mis habilidades de conteo de cartas!",
    proficiencies: "Mis Proficiencias Incluyen:",
    fe: "Desarrollo Front-End",
    be: "Desarrollo Back-End",
    version: "Control de Versiones y Colaboración",
    cloud: "Servicios en la Nube"
}