export const englishContent = {
    name: "Isaiah Sinnathamby",
    about: "About Me",
    exp: "My Experience",
    apps: "My Applications",
    creds: "My Credentials",
    services: "My Services",
    contact: "Get In Touch",
}

export const spanishContent = {
    name: "Isaías Sinnathamby",
    about: "Sobre Mi",
    exp: "Mi Experiencia",
    apps: "Mis Aplicaciones",
    creds: "Mis Credenciales",
    services: "Mis Servicios",
    contact: "Contacto",
}