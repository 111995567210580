import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import AboutMe from '../AboutMe'
import WorkHistory from '../WorkHistory'
import Projects from '../Projects'
import MyCredentials from '../MyCredentials';
import MyServices from '../MyServices';
import profilePicture from '../../assets/port-images/profile-picture.jpg'
import './HomePage.css'

export default function HomePage() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();

    const toggleLanguage = () => {
        setCurrentLanguage(currentLanguage === 'english' ? 'spanish' : 'english');
    };

    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    const aboutRef = useRef(null);
    const historyRef = useRef(null);
    const projectsRef = useRef(null);
    const credentialsRef = useRef(null);
    const servicesRef = useRef(null);
    const contactRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('show');
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        if (aboutRef.current) {
            observer.observe(aboutRef.current);
        }

        if (historyRef.current) {
            observer.observe(historyRef.current);
        }

        if (projectsRef.current) {
            observer.observe(projectsRef.current);
        }

        if (credentialsRef.current) {
            observer.observe(credentialsRef.current);
        }

        if (servicesRef.current) {
            observer.observe(servicesRef.current);
        }

        if (contactRef.current) {
            observer.observe(contactRef.current);
        }

        return () => {
            if (aboutRef.current) {
                observer.unobserve(aboutRef.current);
            }

            if (historyRef.current) {
                observer.unobserve(historyRef.current);
            }

            if (projectsRef.current) {
                observer.unobserve(projectsRef.current);
            }

            if (credentialsRef.current) {
                observer.unobserve(credentialsRef.current);
            }

            if (servicesRef.current) {
                observer.unobserve(servicesRef.current);
            }

            if (contactRef.current) {
                observer.unobserve(contactRef.current);
            }
        };
    }, []);

    return (
        <div className='home-container'>
            <div className='hero-container tektur'>
                <section className='hero-intro fade1'>
                    <h1 className='introduction light-pink'>{content.introduction}</h1>
                    <h2 className='calibre my-name'>{content.name}</h2>
                    <h3 className='calibre gray intro-passion'>{content.introPassion}</h3>
                    <div className='calibre gray intro-md'>{content.introMd}</div>
                    <div className='language-container'>
                        <button className='language-toggle-button' onClick={toggleLanguage}>
                            {currentLanguage === 'english' ? 'Español' : 'English'}
                        </button>
                    </div>
                </section>

                <div className='hero-contact-me'>
                    <div className='pfp-container fade2'>
                        <div className='profile-picture-container'>
                            <a href='https://www.linkedin.com/in/isaiahxs/' target='_blank' rel='noopener noreferrer'>
                                <img src={profilePicture} alt='profile-picture' className='profile-picture' />
                            </a>
                        </div>
                    </div>

                    <div className='hero-buttons-container fade3'>
                        <div className='hero-buttons'>
                            <button onClick={() => window.location.href = 'mailto:isaiahsinnathamby@gmail.com'} className='calibre get-in-touch'>{content.email}</button>
                            <a href='https://www.linkedin.com/in/isaiahxs/' target='_blank' rel='noopener noreferrer' className='linkedin-button'>
                                <button className='linkedin'>
                                    LinkedIn
                                </button>
                            </a>

                            <a href='https://github.com/isaiahxs' target='_blank' rel='noopener noreferrer' className='github-button'>
                                <button className='github'>
                                    GitHub
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={aboutRef} className='body-fade-in' id='about-me'>
                <AboutMe />
            </div>

            <div ref={historyRef} className='body-fade-in' id='work-history'>
                <WorkHistory />
            </div>

            <div ref={projectsRef} className='body-fade-in' id='projects'>
                <Projects />
            </div>

            <div ref={credentialsRef} className='body-fade-in' id='my-credentials'>
                <MyCredentials />
            </div>

            <div ref={servicesRef} className='body-fade-in' id='my-services'>
                <MyServices />
            </div>

            <div ref={contactRef} className='body-fade-in' id='contact'>
                <div className='contact-container'>
                    <div className='heading-container'>
                        <h3 className='heading-number'>06.</h3>
                        <h3 className='heading-text'>{content.cta}</h3>
                    </div>
                    <div className='footer'>
                        <div className='end-cta-container'>
                            <h3 className='end-cta'>{content.endCTA}</h3>
                            <h3 className='end-cta end-cta-two'>{content.nextLevel}</h3>

                            {/* <h3 className='end-cta end-cta-two'>{content.endCTA2}</h3> */}
                            {/* <br />
                            <div>
                                Ready to take your online presence to the next level? Contact me today to schedule a <strong>Free Consultation</strong>.
                            </div> */}
                        </div>
                        <div className='contact-buttons'>
                            <button onClick={() => window.location.href = 'mailto:isaiahsinnathamby@gmail.com'} className='calibre say-hello'>{content.CTAButton}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

