export const englishContent = {
    introduction: "Hi, my name is",
    name: "Isaiah Sinnathamby.",
    introPassion: "I'm passionate about building extraordinary digital experiences.",
    introMd: "I'm a bilingual full-stack software engineer based in Maryland, specializing in designing and developing stunning, high-performance websites.",
    email: "Email",
    cta: "Get In Touch",
    endCTA: "I'm always open to exploring new opportunities and challenges.",
    nextLevel: "Ready to take your online presence to the next level? Contact me today to schedule a free consultation.",
    // endCTA2: "Reach out and let's discuss the exciting possibilities that lie ahead.",
    CTAButton: "Let's Collaborate"
}

export const spanishContent = {
    introduction: "Hola, mi nombre es",
    name: "Isaías Sinnathamby.",
    introPassion: "Me apasiona construir experiencias digitales extraordinarias.",
    introMd: "Soy un ingeniero de software de pila completa bilingüe con sede en Maryland, especializado en diseñar y desarrollar sitios web impresionantes y de alto rendimiento.",
    email: "Correo electrónico",
    cta: "Pongase en contacto",
    endCTA: "Siempre estoy abierto a explorar nuevas oportunidades y desafíos.",
    nextLevel: "¿Listo para llevar su presencia en línea al siguiente nivel? Póngase en contacto conmigo hoy para programar una consulta gratuita.",
    // endCTA2: "Comuníquese y discutamos las emocionantes posibilidades que se avecinan.",
    CTAButton: "Colaboremos"
}