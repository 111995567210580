import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import welldone from '../../assets/port-images/Well-Done-SC.png'
import serene from '../../assets/port-images/SereneHaven-SC.png'
import epic from '../../assets/port-images/Epic-Home.png'
import './Projects.css'

export default function Projects() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    return (
        <div className="projects-container">
            <div className="heading-container my-apps">
                <h3 className="heading-number">03.</h3>
                <h3 className="heading-text">{content.apps}</h3>
            </div>

            <div className='about-me-body-container'>
                <div className='about-me-body'>

                    <div className='other-projects'>
                        <div className='epic'>
                            <div className='project-description'>
                                {/* <div className='project-name-container'> */}
                                <a className='project-name' href='https://epic-e-shop.onrender.com/' target='_blank' rel='noopener noreferrer'>Epic-E-Shop</a>
                                {/* </div> */}
                                <div className='project-description-text'>
                                    {content.E1}
                                    <br />
                                    <br />
                                    {content.E2}
                                </div>

                                <div className='project-technologies'>
                                    <p className='techs'>
                                        React /
                                        Redux /
                                        JavaScript /
                                        HTML /
                                        CSS /
                                        Python /
                                        Flask /
                                        SQLAlchemy /
                                        PostgreSQL
                                    </p>
                                </div>

                                <div className='project-links'>
                                    <a className='external-link' href='https://epic-e-shop.onrender.com/' target='_blank' rel='noopener noreferrer'>
                                        <button className='live-site'>{content.live}</button>
                                    </a>

                                    <a className='external-link' href='https://github.com/isaiahxs/Epic-E-Shop' target='_blank' rel='noopener noreferrer'>
                                        <button className='gh-repo'>{content.gh}</button>
                                    </a>
                                </div>
                            </div>
                            <div className='about-img-container'>
                                <a href='https://epic-e-shop.onrender.com/' target='_blank' rel='noopener noreferrer'>
                                    <img className='project-image' src={epic} alt='epic project' />
                                </a>
                            </div>
                        </div>

                        <div className='well-done'>
                            <div className='project-description'>
                                <a className='project-name' href='https://well-done.onrender.com/' target='_blank' rel='noopener noreferrer'>Well-Done</a>
                                <div className='project-description-text'>
                                    {content.W1}
                                    <br />
                                    <br />
                                    {content.W2}
                                </div>

                                <div className='project-technologies'>
                                    <p className='techs'>
                                        React /
                                        Redux /
                                        AWS /
                                        JavaScript /
                                        HTML /
                                        CSS /
                                        Python /
                                        Flask /
                                        SQLAlchemy /
                                        PostgreSQL
                                    </p>
                                </div>

                                <div className='project-links'>
                                    <a className='external-link' href='https://well-done.onrender.com/' target='_blank' rel='noopener noreferrer'>
                                        <button className='live-site'>{content.live}</button>
                                    </a>
                                    <a className='external-link' href='https://github.com/isaiahxs/well-done-group-project' target='_blank' rel='noopener noreferrer'>
                                        <button className='gh-repo'>{content.gh}</button>
                                    </a>
                                </div>
                            </div>
                            <div className='about-img-container'>
                                <a href='https://well-done.onrender.com/' target='_blank' rel='noopener noreferrer'>
                                    <img className='project-image' src={welldone} alt='well-done project' />
                                </a>
                            </div>
                        </div>

                        <div className='serene-haven'>
                            <div className='project-description'>
                                <a className='project-name' href='https://serene-haven.onrender.com/' target='_blank' rel='noopener noreferrer'>SereneHaven</a>
                                <div className='project-description-text'>
                                    {content.S1}
                                    <br />
                                    <br />
                                    {content.S2}
                                </div>

                                <div className='project-technologies'>
                                    <p className='techs'>
                                        React /
                                        Redux /
                                        JavaScript /
                                        HTML /
                                        CSS /
                                        PostgreSQL /
                                        Express
                                    </p>
                                </div>

                                <div className='project-links'>
                                    <a className='external-link' href='https://serene-haven.onrender.com/' target='_blank' rel='noopener noreferrer'>
                                        <button className='live-site'>{content.live}</button>
                                    </a>

                                    <a className='external-link' href='https://github.com/isaiahxs/SereneHaven' target='_blank' rel='noopener noreferrer'>
                                        <button className='gh-repo'>{content.gh}</button>
                                    </a>
                                </div>
                            </div>
                            <div className='about-img-container'>
                                <a href='https://serene-haven.onrender.com/' target='_blank' rel='noopener noreferrer'>
                                    <img className='project-image' src={serene} alt='serene-haven project' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

