export const englishContent = {
    exp: "Experience",


    SWETitle: "Full-Stack Software Engineer",
    SWEDuration: "August 2020 - Present",
    SWE1: "Design and develop full-stack applications using technologies like React, Redux, Python, Flask, MySQL, and AWS, tailored to clients' specific needs and business objectives",
    SWE2: "Create cohesive brand identities through logo and business card design, aimed to improve client's brand awareness and recognition",
    SWE3: "Employ search engine optimization best practices in website development to improve client's search engine ranking and online visibility",
    SWE4: "Implement metrics and analytics tools to help clients understand user engagement, optimize performance, and achieve key performance indicators",
    SWE5: "Provide post-launch support and maintenance services to ensure the consistent performance and uptime of web applications",


    GATitle: "Full-Stack Software Engineer & Community Manager",
    GADuration: "September 2021 - September 2022",
    GA1: "Co-developed site and managed a top-ranked web3 NFT project with over $60M in volume traded & over 3,000+ stakeholders, ranking in the top 157 projects of all time",
    GA2: "Brought over $1.5M in value to the 3,000+ stakeholders in 6 months via allowlists by networking with other project creators, community managers, artists, & developers before ownership transfer",
    GA3: "Implemented performance optimization techniques, reducing load times by 30% & leading to a 20% improvement in user satisfaction and engagement",


    ITitle: "Executive Assistant & Operations Manager",
    IDuration: "June 2019 - Present",
    I1: `Orchestrated the salon’s daily operations, including appointment scheduling, staff rostering, and inventory
    management, enhancing customer experience and increasing retention rates by 15%`,
    I2: `Implemented a comprehensive client management system, improving operational efficiency and streamlining
    service records, which reduced scheduling times by 25% and increased revenue by 15% through optimized
    pricing strategies`,
    I3: `Led the development of internal communication protocols, fostering a cohesive team environment, which
    resulted in a 30% reduction in staff turnover and facilitated effective conflict resolution`,
    I4: `Conducted financial reporting and budget management, optimizing product pricing strategies and reducing
    reconciliation times by 50%, ensuring 100% accuracy in daily financial transactions`,


    STitle: "Administrative Assistant & Guest Services Coordinator",
    SDuration: "June 2016 - June 2019",
    S1: `Served as a key liaison between the kitchen and dining area, optimizing the flow of orders and enhancing guest
    satisfaction in a high-volume setting, reducing order delivery times by 35%`,
    S2: `Employed bilingual skills to bridge communication gaps, improving staff efficiency by 25% and enhancing
    customer service, which contributed to a 20% increase in repeat customer visits`,
    S3: `Developed and maintained efficient systems for managing guest waitlists and reservations, cutting average wait
    times by 50%, and significantly improving the dining experience`,
    S4: `Addressed and resolved guest concerns with diplomacy and tact, directly contributing to a 30% growth in
    customer loyalty through exceptional service recovery efforts`
}

export const spanishContent = {
    exp: "Experiencia",


    SWETitle: "Desarrollador Full-Stack",
    SWEDuration: "Agosto 2020 - Presente",
    SWE1: "Diseñar y desarrollar aplicaciones full-stack utilizando tecnologías como React, Redux, Python, Flask, MySQL y AWS, adaptadas a las necesidades específicas y los objetivos comerciales de los clientes",
    SWE2: "Crear identidades de marca cohesivas a través del diseño de logotipos y tarjetas de presentación, con el objetivo de mejorar el conocimiento y el reconocimiento de la marca del cliente",
    SWE3: "Emplear las mejores prácticas de optimización de motores de búsqueda en el desarrollo de sitios web para mejorar el ranking de los motores de búsqueda del cliente y la visibilidad en línea",
    SWE4: "Implementar herramientas de métricas y análisis para ayudar a los clientes a comprender la participación de los usuarios, optimizar el rendimiento y lograr los indicadores clave de rendimiento",
    SWE5: "Proporcionar servicios de soporte y mantenimiento posteriores al lanzamiento para garantizar el rendimiento y el tiempo de actividad constantes de las aplicaciones web",


    GATitle: "Desarrollador Full-Stack y Gerente de Comunidad",
    GADuration: "Septiembre de 2021 - Septiembre de 2022",
    GA1: "Co-desarrollé el sitio y gestioné un proyecto NFT web3 de alto rango con más de $60 millones en volumen de negocios y más de 3,000+ accionistas, clasificado entre los 157 mejores proyectos de todos los tiempos",
    GA2: "Aporté más de $1.5 millones en valor a los 3,000+ accionistas en 6 meses a través de listas de permisos, estableciendo conexiones con otros creadores de proyectos, gerentes de comunidades, artistas y desarrolladores antes de la transferencia de propiedad",
    GA3: "Implementé técnicas de optimización de rendimiento que redujeron los tiempos de carga en un 30% y llevaron a un aumento del 20% en la satisfacción y la participación de los usuarios",


    ITitle: "Asistente Ejecutivo y Gerente de Operaciones",
    IDuration: "Junio 2019 - Presente",
    I1: "Orquesté las operaciones diarias del salón, incluyendo la programación de citas, organización del personal y gestión del inventario, lo que mejoró la experiencia del cliente e incrementó las tasas de retención en un 15%",
    I2: "Implementé un sistema integral de gestión de clientes que aumentó la eficiencia operativa, simplificó los registros de servicios, redujo los tiempos de programación en un 25% e incrementó los ingresos en un 15% mediante estrategias de precios optimizadas",
    I3: "Lideré el desarrollo de protocolos de comunicación interna, fomentando un entorno de equipo cohesionado que redujo la rotación de personal en un 30% y facilitó una resolución de conflictos efectiva",
    I4: "Conduje la gestión de informes financieros y presupuestos, optimicé las estrategias de precios de productos y reduje los tiempos de conciliación en un 50%, asegurando una precisión del 100% en las transacciones financieras diarias",

    STitle: "Asistente Administrativo y Coordinador de Servicios al Cliente",
    SDuration: "Junio 2019 - Junio 2019",
    S1: "Actué como enlace clave entre la cocina y el área de comedor, optimizando el flujo de pedidos y mejorando la satisfacción de los clientes en un entorno de alto volumen, reduciendo los tiempos de entrega de los pedidos en un 35%",
    S2: "Utilicé habilidades bilingües para cerrar brechas de comunicación, mejorando la eficiencia del personal en un 25% y mejorando el servicio al cliente, lo que contribuyó a un aumento del 20% en las visitas repetidas de los clientes",
    S3: "Desarrollé y mantuve sistemas eficientes para gestionar las listas de espera y las reservas de los clientes, reduciendo los tiempos de espera promedio en un 50% y mejorando significativamente la experiencia en el comedor",
    S4: "Atendí y resolví las preocupaciones de los clientes con diplomacia y tacto, contribuyendo directamente a un crecimiento del 30% en la lealtad del cliente a través de esfuerzos excepcionales de recuperación del servicio"
}