import React, { useState } from 'react';
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import './WorkHistory.css';

export default function WorkHistory() {
    const [selectedWorkplace, setSelectedWorkplace] = useState('App Academy & Freelance');

    const handleWorkplaceClick = (workplace) => {
        setSelectedWorkplace(workplace);
    };

    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    return (
        <div className='work-history-container'>
            <div className='heading-container'>
                <h3 className='heading-number'>02.</h3>
                <h3 className='heading-text'>{content.exp}</h3>
            </div>

            <div className='work-history'>
                <div className='work-place-column-container'>
                    <div className='work-place-column'>
                        <button onClick={() => handleWorkplaceClick('App Academy & Freelance')} className={`work-place-button ${selectedWorkplace === 'App Academy & Freelance' ? 'active' : ''}`}>App Academy & Freelance</button>

                        <button onClick={() => handleWorkplaceClick('GalacticApes')} className={`work-place-button ${selectedWorkplace === 'GalacticApes' ? 'active' : ''}`}>GalacticApes</button>

                        <button onClick={() => handleWorkplaceClick("Isaula's Beauty Salon")} className={`work-place-button ${selectedWorkplace === "Isaula's Beauty Salon" ? 'active' : ''}`}>Isaula's Beauty Salon</button>

                        <button onClick={() => handleWorkplaceClick('Sin Fronteras')} className={`work-place-button ${selectedWorkplace === 'Sin Fronteras' ? 'active' : ''}`}>Sin Fronteras</button>
                    </div>
                </div>


                <div className='work-details-column'>
                    {selectedWorkplace === "App Academy & Freelance" &&
                        <div className='app-academy'>
                            <h2 className='work-role'>
                                {content.SWETitle}
                            </h2>

                            <div className='duration'>
                                {content.SWEDuration}
                            </div>

                            <div className='work-description'>
                                <ul>
                                    <li>
                                        {content.SWE1}
                                    </li>
                                    <li>
                                        {content.SWE2}
                                    </li>
                                    <li>
                                        {content.SWE3}
                                    </li>
                                    <li>
                                        {content.SWE4}
                                    </li>
                                    <li>
                                        {content.SWE5}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }

                    {selectedWorkplace === 'GalacticApes' &&
                        <div className='galactic-apes'>
                            <h2 className='work-role'>
                                {content.GATitle}
                            </h2>
                            <div className='duration'>
                                {content.GADuration}
                            </div>
                            <div className='work-description'>
                                <ul>
                                    <li>{content.GA1}</li>
                                    <li>{content.GA2}</li>
                                    <li>{content.GA3}</li>
                                </ul>
                            </div>
                        </div>
                    }

                    {selectedWorkplace === "Isaula's Beauty Salon" &&
                        <div className='isaulas-beauty-salon'>
                            <h2 className='work-role'>
                                {content.ITitle}
                            </h2>

                            <div className='duration'>
                                {content.IDuration}
                            </div>

                            <div className='work-description'>
                                <ul>
                                    <li>
                                        {content.I1}
                                    </li>
                                    <li>
                                        {content.I2}
                                    </li>
                                    <li>
                                        {content.I3}
                                    </li>
                                    <li>
                                        {content.I4}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }

                    {selectedWorkplace === 'Sin Fronteras' &&
                        <div className='sin-fronteras'>
                            <h2 className='work-role'>
                                {content.STitle}
                            </h2>
                            <div className='duration'>
                                {content.SDuration}
                            </div>
                            <div className='work-description'>
                                <ul>
                                    <li>
                                        {content.S1}
                                    </li>
                                    <li>
                                        {content.S2}
                                    </li>
                                    <li>
                                        {content.S3}
                                    </li>
                                    <li>
                                        {content.S4}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }


                </div>
            </div>
        </div>
    )
}