import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import resume from '../../assets/resume/Sinnathamby_Isaiah_Resume.pdf'
import logo from '../../assets/port-images/letter-i.png';
import hamburger from '../../assets/port-images/hamburger-pink.png';
import './Navigation.css';

function Navigation({ isLoaded }) {
	const navRef = useRef();

	const [isNavOpen, setIsNavOpen] = useState(false);

	const toggleNavOpen = () => {
		setIsNavOpen(!isNavOpen);
	};

	const { currentLanguage, setCurrentLanguage } = useLanguage();

	const toggleLanguage = () => {
		setCurrentLanguage(currentLanguage === 'english' ? 'spanish' : 'english');
	};

	const content = currentLanguage === 'english' ? englishContent : spanishContent;

	const [lastScrollPos, setLastScrollPos] = useState(0);

	useEffect(() => {
		if (isNavOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [isNavOpen]);

	useEffect(() => {
		const checkIfClickedOutside = e => {
			if (isNavOpen && navRef.current && !navRef.current.contains(e.target)) {
				setIsNavOpen(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isNavOpen]);

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		const currentScrollPos = window.pageYOffset;
	// 		const isScrollingDown = currentScrollPos > lastScrollPos;
	// 		const hamburgerMenu = document.querySelector('.hamburger-menu');

	// 		if (isScrollingDown) {
	// 			// User is scrolling down, hide the hamburger menu
	// 			hamburgerMenu.style.transform = 'translateY(-100%)';
	// 		} else {
	// 			// User is scrolling up, show the hamburger menu
	// 			hamburgerMenu.style.transform = 'translateY(0)';
	// 		}

	// 		// Remember the current scroll position for the next scroll event
	// 		setLastScrollPos(currentScrollPos);
	// 	};

	// 	// Add the scroll event listener
	// 	window.addEventListener('scroll', handleScroll);

	// 	// Cleanup function
	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, [lastScrollPos]);

	const scrollToSection = (sectionId) => {
		setIsNavOpen(false);

		setTimeout(() => {
			const sectionElement = document.getElementById(sectionId);
			const yOffset = -100;
			const topOffset = sectionElement.getBoundingClientRect().top + window.scrollY + yOffset;
			window.scrollTo({ top: topOffset, behavior: 'smooth' });
			// sectionElement.scrollIntoView({ behavior: 'smooth' });
		}, 300);
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<div className='nav-bar'>
			<div className='inner-nav'>
				<div className='nav-logo-container fade-in'>
					<img src={logo} className='small-icon' alt="Isaiahxs Logo" onClick={() => scrollToTop()} />
				</div>

				<div className='nav-name fade-in'>
					{content.name}
				</div>

				<button className='hamburger-menu fade-in' onClick={toggleNavOpen}>
					<img src={hamburger} alt='Hamburger Icon' className='small-icon' />
				</button>

				{isNavOpen && <div className='backdrop'></div>}

				<div ref={navRef} className={`nav-panel ${isNavOpen ? 'nav-open' : ''}`}>
					<button className='x-button' onClick={toggleNavOpen}>X</button>

					<section className='inner-panel'>
						<div className='nav-button-container panel-language-container'>
							<button className='panel-language-button' onClick={toggleLanguage}>
								{currentLanguage === 'english' ? 'Español' : 'English'}
							</button>
						</div>

						<div className='nav-button-container'>
							<p className='nav-number light-pink tektur'>01.</p>
							<button className='nav-button' onClick={() => scrollToSection('about-me')}>
								{content.about}
							</button>
						</div>

						<div className='nav-button-container'>
							<p className='nav-number light-pink tektur'>02.</p>
							<button className='nav-button' onClick={() => scrollToSection('work-history')}>
								{content.exp}
							</button>
						</div>

						<div className='nav-button-container'>
							<p className='nav-number light-pink tektur'>03.</p>
							<button className='nav-button' onClick={() => scrollToSection('projects')}>
								{content.apps}
							</button>
						</div>

						<div className='nav-button-container'>
							<p className='nav-number light-pink tektur'>04.</p>
							<button className='nav-button' onClick={() => scrollToSection('my-credentials')}>
								{content.creds}
							</button>
						</div>

						<div className='nav-button-container'>
							<p className='nav-number light-pink tektur'>05.</p>
							<button className='nav-button' onClick={() => scrollToSection('my-services')}>
								{content.services}
							</button>
						</div>

						<div className='nav-button-container'>
							<p className='nav-number light-pink tektur'>06.</p>
							<button className='nav-button' onClick={() => scrollToSection('contact')}>
								{content.contact}
							</button>
						</div>

						<div className='resume-container'>
							<a href={resume} target='_blank' rel='noopener noreferrer'>
								<button className='resume'>Resume</button>
							</a>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}

export default Navigation;