import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import profilePicture from '../../assets/port-images/profile-picture.jpg'
import linkedin from '../../assets/port-images/linkedin-logo.svg'
import github from '../../assets/port-images/github-logo.svg'
import react from '../../assets/port-images/react.svg'
import redux from '../../assets/port-images/redux.svg'
import javascript from '../../assets/port-images/javascript.svg'
import html from '../../assets/port-images/html.svg'
import css from '../../assets/port-images/css.svg'
import python from '../../assets/port-images/python.svg'
import flask from '../../assets/port-images/flask.svg'
import node from '../../assets/port-images/node.svg'
import express from '../../assets/port-images/express.svg'
import sqlalchemy from '../../assets/port-images/sqlalchemy.svg'
import psql from '../../assets/port-images/psql.svg'
import mysql from '../../assets/port-images/mysql.svg'
import aws from '../../assets/port-images/aws.svg'
import git from '../../assets/port-images/git.svg'
import github_icon from '../../assets/port-images/github.svg'
import gh_no_word from '../../assets/port-images/github-no-word.svg'

import './AboutMe.css'

export default function AboutMe() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    return (
        <div className='about-container'>
            <div className='heading-container'>
                <h3 className='heading-number'>01.</h3>
                <h3 className='heading-text'>{content.about}</h3>
            </div>
            <div className='about-body-text'>
                {/* <p>Hello, I'm Isaiah, a full-stack software engineer based in Maryland.</p> */}
                <p className='first-about-text'>{content.firstAbout}</p>
                <p className='second-about-text'>{content.secondAbout}</p>
                <p className='third-about-text'>{content.thirdAbout}</p>
            </div>
            <div className='prof-container'>
                <p className='prof-sentence'>{content.proficiencies}</p>
            </div>
            <div className='skills-container'>
                <div className='skills-column'>
                    <h4 className='tech-headers'>{content.fe}</h4>
                    <div className='tech-grid'>
                        <div className='tech-card'>
                            <img src={react} alt='react' className='tech-logo' />
                            <p>React</p>
                        </div>

                        <div className='tech-card'>
                            <img src={redux} alt='redux' className='tech-logo' />
                            <p>Redux</p>
                        </div>

                        <div className='tech-card'>
                            <img src={javascript} alt='javascript' className='tech-logo' />
                            <p>JavaScript</p>
                        </div>

                        <div className='tech-card'>
                            <img src={html} alt='html' className='tech-logo' />
                            <p>HTML</p>
                        </div>

                        <div className='tech-card'>
                            <img src={css} alt='css' className='tech-logo' />
                            <p>CSS</p>
                        </div>
                    </div>
                </div>

                <div className='skills-column'>
                    <h4 className='tech-headers'>{content.be}</h4>
                    <div className='tech-grid'>
                        <div className='tech-card'>
                            <img src={python} alt='python' className='tech-logo' />
                            <p>Python</p>
                        </div>

                        <div className='tech-card'>
                            <img src={sqlalchemy} alt='sqlalchemy' className='tech-logo white-background' />
                            <p>SQLAlchemy</p>
                        </div>

                        <div className='tech-card'>
                            <img src={flask} alt='flask' className='tech-logo white-background' />
                            <p>Flask</p>
                        </div>

                        <div className='tech-card'>
                            <img src={mysql} alt='mysql' className='tech-logo white-background' />
                            <p>MySQL</p>
                        </div>

                        <div className='tech-card'>
                            <img src={psql} alt='psql' className='tech-logo white-background' />
                            <p>PostgreSQL</p>
                        </div>

                        <div className='tech-card'>
                            <img src={express} alt='express' className='tech-logo white-background' />
                            <p>Express</p>
                        </div>

                        <div className='tech-card'>
                            <img src={node} alt='node.js' className='tech-logo white-background' />
                            <p>Node.js</p>
                        </div>
                    </div>
                </div>

                <div className='skills-column'>
                    <h4 className='tech-headers'>{content.version}</h4>
                    <div className='tech-grid'>
                        <div className='tech-card'>
                            <img src={git} alt='git' className='tech-logo white-background' />
                            <p>Git</p>
                        </div>

                        <div className='tech-card'>
                            <img src={gh_no_word} alt='github' className='tech-logo white-background' />
                            <p>GitHub</p>
                        </div>
                    </div>
                </div>

                <div className='skills-column'>
                    <h4 className='tech-headers'>{content.cloud}</h4>
                    <div className='tech-grid'>
                        <div className='tech-card'>
                            <img src={aws} alt='aws' className='tech-logo white-background' />
                            <p>AWS</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}